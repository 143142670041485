<template>
  <BCard class="container">
    <div class="d-flex">
      <BButton
        variant="primary"
        size="sm"
        class="rounded-lg mr-2"
        @click="$router.back()"
      >
        <b-icon-chevron-left />
      </BButton>
      <span class="text-[24px] text-black font-bold my-auto">Notification Bar</span>
    </div>
    <ValidationObserver #default="{invalid}">
      <BRow class="my-3">
        <BCol md="6">
          <ValidationProvider
            #default="{ errors }"
            name="Judul"
            rules="required"
          >
            <BFormGroup>
              <label
                class="text-lg"
                for="title"
              >
                Judul
              </label>

              <BFormInput
                v-model="title"
                :state="errors.length > 0 ? false : null"
                placeholder="Masukan judul notifikasi kamu"
                class="mb-1"
              />
              <small class="text-primary">{{ errors[0] }}</small>
            </BFormGroup>
          </ValidationProvider>
          <ValidationProvider
            #default="{ errors }"
            name="Deskripsi"
            rules="required"
          >
            <BFormGroup>
              <label
                class="text-lg"
                for="title"
              >
                Deskripsi
              </label>
              <BFormTextarea
                v-model="desc"
                :state="errors.length > 0 ? false : null"
                placeholder="Masukan deskripsi kamu"
                class="mb-1"
                rows="4"
              />
              <small class="text-primary">{{ errors[0] }}</small>
            </BFormGroup>
          </ValidationProvider>
          <BRow>
            <BCol lg="6">
              <BFormGroup
                class="mr-2 text-lg"
                label="Link Url"
              >
                <BFormInput
                  v-model="link"
                  placeholder="Masukan link URL"
                  class="mb-1"
                />
              </BFormGroup>
            </BCol>
            <BCol lg="6">
              <ValidationProvider
                #default="{ errors }"
                name="Warna"
                rules="required"
              >
                <BFormGroup>
                  <label
                    class="text-lg"
                    for="title"
                  >Warna</label>
                  <div
                    class="p-1 border-[1px] border-[#E2E2E2] rounded-[49px]"
                    style="max-width: 235px"
                  >
                    <button
                      v-for="items in colorOptions"
                      :id="items.id"
                      :key="items.id"
                      :state="items.active ? true : null"
                      class="color-options rounded-full ml-1"
                      :class="items.active ? 'active' : ''"
                      @click="setColors(items)"
                    />
                  </div>
                  <small class="text-primary">{{ errors[0] }}</small>
                </BFormGroup>
              </ValidationProvider>
            </BCol>
          </BRow>
          <BRow class="mt-2">
            <ValidationProvider
              #default="{ errors }"
              label="Platform"
              name="Platform"
              rules="required"
            >
              <BCol
                lg="12"
                class="flex items-center"
              >
                <div
                  v-for="service in platform"
                  :key="service.id"
                  class="flex items-center mr-1"
                >
                  <BFormCheckbox v-model="service.onCheck" />
                  <img
                    :src="service.img_url"
                    alt="Komerce"
                    class="w-[100px]"
                  >
                </div>
                <small class="text-primary">{{ errors[0] }}</small>
              </BCol>
            </ValidationProvider>
          </BRow>
        </BCol>
        <BCol md="6">
          <div class="d-flex justify-content-end mb-1">
            <BButton
              :variant="invalid ? 'outline-secondary' : 'outline-primary'"
              class="rounded-lg"
              :disabled="invalid || checkStatus() || !color"
              @click="submit(0)"
            >
              Draft
            </BButton>
            <BButton
              :variant="invalid ? 'secondary' : 'primary'"
              class="rounded-lg ml-1"
              :disabled="invalid || checkStatus() || !color"
              @click="submit(1)"
            >
              Publish
            </BButton>
          </div>
          <div class="bg-[#F8F8F8] d-flex py-2">
            <img
              v-if="colorOptions[0].active "
              src="https://storage.googleapis.com/komerce/assets/banner/preview-notif-success.svg"
              alt="Komerce"
              class="mx-auto"
            >
            <img
              v-if="colorOptions[1].active "
              src="https://storage.googleapis.com/komerce/assets/banner/preview-notif-warning.svg"
              alt="Komerce"
              class="mx-auto"
            >
            <img
              v-if="colorOptions[2].active "
              src="https://storage.googleapis.com/komerce/assets/banner/preview-notif-danger.svg"
              alt="Komerce"
              class="mx-auto"
            >
            <img
              v-if="colorOptions[3].active "
              src="https://storage.googleapis.com/komerce/assets/banner/preview-notif-info.svg"
              alt="Komerce"
              class="mx-auto"
            >
          </div>
        </BCol>
      </BRow>
    </ValidationObserver>
  </BCard>
</template>
<script>
import ToastificationContentVue from '@/@core/components/toastification/ToastificationContent.vue'
import { komshipAxiosIns } from '@/libs/axios'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import { platform } from './config'

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
  },
  data() {
    return {
      colorOptions: [
        { id: 'colors-1', value: '#B6FBC9', active: false },
        { id: 'colors-2', value: '#FFDF82', active: false },
        { id: 'colors-3', value: '#F86363', active: false },
        { id: 'colors-4', value: '#BEE6FC', active: false },
      ],
      title: null,
      desc: null,
      link: null,
      color: null,
      status: null,
      serviceType: null,
      platform,
      required,
    }
  },
  mounted() {
    this.getDetailNotification()
  },
  methods: {
    setColors(items) {
      // eslint-disable-next-line no-plusplus
      for (let x = 0; x < this.colorOptions.length; x++) {
        if (this.colorOptions[x].id === items.id) {
          this.colorOptions[x].active = true
        } else {
          this.colorOptions[x].active = false
        }
      }
      this.color = items.value
    },
    async getDetailNotification() {
      const url = `/v1/admin/notification-bar/detail/${this.$route.params.id}`
      await komshipAxiosIns.get(url)
        .then(result => {
          const { data } = result.data
          this.title = data.title
          this.desc = data.description
          this.link = data.url_link
          this.color = data.color
          this.status = data.status
          const colors = this.colorOptions.find(items => items.value === this.color)
          this.setColors(colors)
          const service = data.service_type.split(',')
          const compareDefaultService = this.platform
            .filter(items => items.name === service)
            .map(items => items.name)
          if (service.length !== compareDefaultService.length) {
            this.platform.forEach(items => {
              if (service.includes(items.name)) {
                // eslint-disable-next-line no-param-reassign
                items.onCheck = true
              } else {
                // eslint-disable-next-line no-param-reassign
                items.onCheck = false
              }
            })
          }
        })
        .catch(err => {
          this.isLoading = false
          this.$toast({
            component: ToastificationContentVue,
            props: {
              title: 'Failure',
              icon: 'AlertCircleIcon',
              text: err.response.data.message,
              variant: 'danger',
            },
          }, { timeout: 1000 })
        })
    },
    async submit(status) {
      const post = () => {
        const url = '/v1/admin/notification-bar'
        const payload = {
          action: 'edit',
          title: this.title,
          desc: this.desc,
          link: this.link,
          id: this.$route.params.id,
          color: this.color,
          status,
          service_type: this.platform.filter(items => items.onCheck).map(items => items.name),
        }
        komshipAxiosIns.post(url, payload)
          .then(() => {
            this.$router.push('/campaign/notification')
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Success',
                icon: 'CheckCircleIcon',
                text: 'Notifikasi berhasil diubah',
                variant: 'success',
              },
            }, { timeout: 1000 })
          })
          .catch(err => {
            this.isLoading = false
            this.$toast({
              component: ToastificationContentVue,
              props: {
                title: 'Failure',
                icon: 'AlertCircleIcon',
                text: err.response.data.message,
                variant: 'danger',
              },
            }, { timeout: 1000 })
          })
      }
      if (status === 1) {
        this.$swal({
          title: '<span class="font-medium text-[16px]">Kamu yakin ingin mempublish notifikasi ini?</span>',
          // eslint-disable-next-line global-require
          imageUrl: require('@/assets/images/icons/warning.svg'),
          confirmButtonText: 'Publish',
          confirmButtonClass: 'btn btn-primary rounded-lg',
          showCancelButton: true,
          cancelButtonText: 'Batal',
          cancelButtonClass: 'btn btn-outline-primary bg-white text-primary rounded-lg',
        }).then(then => {
          if (then.isConfirmed) {
            post()
          }
        })
      } else {
        post()
      }
    },
    checkStatus() {
      const test = this.platform.filter(item => item.onCheck)
      if (test.length === 0) {
        return true
      }
      return false
    },
    // packingOptions(value) {
    //   return value.packing_detail.filter(e => e.onCheck === true)
    // },
  },
}
</script>
<style lang="scss" src="./notification.scss" />
